import { CustomCoursePromos } from "@/components/CustomCoursePromos";
import Heading from "@/components/Heading";
import Link from "@/components/Link";
import Para from "@/components/Para";
import SpacePage from "@/components/SpacePage";
import SpacePic from "@/components/SpacePic";
import SpaceSection from "@/components/SpaceSection";
import useAccent from "@/hooks/useAccent";
import {
  Box,
  chakra,
  Flex,
  Image,
  List,
  ListItem,
  Stack,
  Text,
} from "@chakra-ui/react";
import { PageProps } from "gatsby";
import React, { ReactElement } from "react";
import { ScalaCoursePromos } from "./training/scala";

function Hero() {
  const accent = useAccent();

  return (
    <Flex
      flexDirection={["column", "row"]}
      justifyContent="stretch"
      alignItems="center"
    >
      <Box flexGrow={1} textAlign={["center", "left"]}>
        <Heading level="page" size="xl">
          Scala training
          <br />
          that&apos;s{" "}
          <chakra.span
            display="inline-block"
            backgroundImage={`url(/images/marker/marker-${accent}.svg)`}
            backgroundRepeat="no-repeat"
            backgroundSize="100% 100%"
            px="4"
            color="white"
          >
            meowt
          </chakra.span>{" "}
          of this world!
        </Heading>
        <Para fontSize="xl">
          Scala training, on-site or remote, for teams of any size and level of
          experience
        </Para>
      </Box>
      <Box>
        <SpacePic my="6" w="560px" name="spacecat" />
      </Box>
    </Flex>
  );
}

// export function CoursePromos(): ReactElement {
//   return (
//     <Promo.Grid blocks={2}>
//       <Promo.Block
//         title="Scala Training"
//         image={<SpacePic name="scala-logos" w="96" alignSelf="center" />}
//         button={
//           <LinkButton variant="outline" to="/training/scala">
//             See our Scala Courses
//           </LinkButton>
//         }
//       >
//         Build robust, scalable web services in Scala. Master the power of
//         functional programming and write code that is robust, concise, and
//         performant.
//       </Promo.Block>
//       <Promo.Block
//         title="TypeScript Training"
//         image={<SpacePic name="typescript-logos" w="96" alignSelf="center" />}
//         button={
//           <LinkButton variant="outline" to="/training/typescript">
//             See our TypeScript Courses
//           </LinkButton>
//         }
//       >
//         Create inspiring UIs using TypeScript and React. Learn effective static
//         typing to make your code robust and maintainable.
//       </Promo.Block>
//     </Promo.Grid>
//   );
// }

const customers = [
  { name: "HMRC", src: "/images/customers/hmrc.png" },
  { name: "Comcast", src: "/images/customers/comcast.png" },
  { name: "Capgemini", src: "/images/customers/capgemini.png" },
  { name: "Goodlord", src: "/images/customers/goodlord.jpg" },
  { name: "YNAP", src: "/images/customers/ynap.png" },
  { name: "ITV", src: "/images/customers/itv.png" },
];

function CustomerLogos(): ReactElement {
  const logoHeight = "72px";
  return (
    <Stack spacing="8">
      <Heading level="section" mt="0" mb="0" textAlign={["left", "center"]}>
        Customers
      </Heading>
      <Text textAlign={["left", "center"]}>
        We&apos;ve been teaching developers about functional programming for 15
        years. Here are some folks we&apos;ve worked with:
      </Text>
      <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
        {customers.map(({ name, src }) => (
          <chakra.div mx="4" key={name}>
            <Image src={src} alt={name} height={logoHeight} />
          </chakra.div>
        ))}
      </Flex>
    </Stack>
  );
}

function PartnerPromo() {
  return (
    <Stack spacing="8">
      <Heading level="section" mt="0" mb="0" textAlign={["left", "center"]}>
        Partners
      </Heading>
      <Flex
        flexDirection={["column", "row"]}
        justifyContent="stretch"
        alignItems="center"
      >
        <Box mr="16">
          <Text>
            <strong>
              Spacecat is proud to be an{" "}
              <a href="https://underscore.io">Underscore</a> training partner!
            </strong>
          </Text>
          <Text mt="4">
            We are a core contributor to open source text books and training
            materials:
          </Text>
          <List mt="4">
            <ListItem>
              <Link
                target="_blank"
                to="https://underscore.io/books/essential-scala"
              >
                Essential Scala
              </Link>
            </ListItem>
            <ListItem>
              <Link target="_blank" to="https://scalawithcats.org">
                Scala with Cats
              </Link>
            </ListItem>
            <ListItem>
              <Link
                target="_blank"
                to="https://underscore.io/books/shapeless-guide"
              >
                The Type Astronaut&apos;s Guide to Shapeless
              </Link>
            </ListItem>
          </List>
        </Box>
        <Box>
          <SpacePic my="6" w="240px" name="telly" />
        </Box>
      </Flex>
    </Stack>
  );
}

export default function Home(_props: PageProps): ReactElement {
  return (
    <SpacePage title="Home" accent="purple" activeMenuItem="home" bg="beige">
      <SpaceSection bg="beige" pt={["8", "16"]} pb={["0", "16"]}>
        <Hero />
      </SpaceSection>
      <SpaceSection bg="white" pt={["32", "48"]} pb="16" ct="lg">
        {/* <CoursePromos /> */}
        <ScalaCoursePromos />
        <CustomCoursePromos />
      </SpaceSection>
      <SpaceSection bg="white" pt="16" pb={["24", "48"]} cb="sm">
        <CustomerLogos />
      </SpaceSection>
      <SpaceSection bg="beige" pt={["16", "24"]} pb={["24", "32"]}>
        <PartnerPromo />
      </SpaceSection>
    </SpacePage>
  );
}
